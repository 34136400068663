import { HTTP } from '.';
import moment from 'moment';
import buildQuery from '@/helpers/url';

export default new class ReportsService {
  constructor() {}

  getInspectionsReport() {
    return HTTP.get(
      '/v1/reports/inspections');
  }

  getVenueSpecialistsReport() {
    return HTTP.get(
      '/v1/reports/venue-specialists');
  }

  getSalesHitList(country) {
    return HTTP.get(
      '/v1/reports/sales-hit-lists?country=' + country);
  }

  getLeadingIndicatorReport(params) {
    let _params = {
      month: params.month,
      country: params.country,
      force_include_venuespecialist_id: params.force_include_venuespecialist_id,
      load_all: params.load_all ? 1 : 0,
      report_type: params.report_type,
      lead_type: params.lead_type,
    };
    return HTTP.get(
      '/v1/reports/leading-indicating-report?' + buildQuery(_params));
  }

  getExcludedLeadingIndicatorVCs() {
    return HTTP.get(
      '/v1/reports/leading-indicating-report/excludes');
  }

  updateExcludedLeadingIndicatorVCs(ids) {
    return HTTP.put(
      '/v1/reports/leading-indicating-report/excludes', {
        venue_specialists: ids,
      });
  }

  getInspections(data) {
    if (!data) {
      return this.emptyData();
    }
    return this.formatTotalInspectionData(data);
  }

  colors(index) {
    let colors = [
      '255, 99, 132',
      '255, 159, 64',
      '255, 205, 86',
      '75, 192, 192',
      '54, 162, 235',
      '153, 102, 255',
      '201, 203, 207',
    ];
    return colors[index%colors.length];
  }

  formatVenueSpecialistsCount(data) {
    let labels = [];
    let _data = [];
    // Create labels first
    for (const [ date, value ] of Object.entries(data)) {
      let label = moment('01-' + date, 'DD-MMM-YYYY').format('YYYYMMDD');
      labels.push(label);
    }
    labels = this.sortByMonthValue(labels);

    // Now label ready and assign label specific values to respective data points.
    for (const date  of labels) {
      let label = moment(date, 'YYYYMMDD').format('MMMM-YYYY');
      _data.push(Math.round(data[label]) || 0);
    }

    // format label
    labels = this.formatByMonthValue(labels);
    return {
      labels,
      datasets: [
        {
          data: _data,
        },
      ],
    };
  }

  formatSingleKeyValues(data) {
    let ukdata = [];
    let audata = [];
    let labels = [];

    // Create labels first
    for (const [ date, value ] of Object.entries(data.uk)) {
      let label = moment('01-' + date, 'DD-MMM-YYYY').format('YYYYMMDD');
      labels.push(label);
    }
    for (const [ date, value ] of Object.entries(data.au)) {
      let label = moment('01-' + date, 'DD-MMM-YYYY').format('YYYYMMDD');
      if (labels.indexOf(label) === -1) {
        labels.push(label);
      }
    }
    labels = this.sortByMonthValue(labels);

    // Now label ready and assign label specific values to respective data points.
    for (const date  of labels) {
      let label = moment(date, 'YYYYMMDD').format('MMMM-YYYY');
      audata.push(Math.round(data.au[label]) || 0);
      ukdata.push(Math.round(data.uk[label]) || 0);
    }

    // format label
    labels = this.formatByMonthValue(labels);
    return {
      labels,
      datasets: [
        {
          label: "AU",
          data: audata,
        },
        {
          label: "UK",
          data: ukdata,
          borderColor: 'rgb( 255, 87, 51 )',
          backgroundColor: 'rgb( 255, 87, 51 )',
          fill: false,
        },
      ],
    };
  }

  getKeysIntersectingValue(data, yaxisKey, yaxisValue, date_by_month) {
    for (const [key, value] of Object.entries(data)) {
      if (value[yaxisKey] === yaxisValue && value.date_by_month === date_by_month) {
        return value.count;
      }
    }
  }

  formatMultipleKeyValues(data, yAxisKey) {
    let labels = [];
    let yAxisValues = [];
    // Create labels first
    for (const [key, value] of Object.entries(data)) {
      let label = moment('01-' + value.date_by_month, 'DD-MMM-YYYY').format('YYYYMMDD');
      if (labels.indexOf(label) === -1) {
        labels.push(label);
      }
      
      let yAxisValue = value[yAxisKey];
      if (yAxisValue && yAxisValues.indexOf(yAxisValue) === -1) {
        yAxisValues.push(yAxisValue);
      }
    }

    labels = this.sortByMonthValue(labels);

    // Now label ready and assign label specific values to respective data points.
    let dataPoints = [];
    let i = 0;
    for (const yAxis of yAxisValues) {
      let _data = []; 
      for (const date  of labels) {
        let dateByMonth = moment(date, 'YYYYMMDD').format('MMMM-YYYY');
        _data.push(this.getKeysIntersectingValue(data, yAxisKey, yAxis, dateByMonth) || 0);
      }
      let color = this.colors(i);

      dataPoints.push({
        data: _data,
        label: yAxis,
        fill: false,
        borderColor: 'rgb(' +color + ')',
        backgroundColor: 'rgba(' +color + ', .5)',
      });
      i = i + 1;
    }
    // format label
    labels = this.formatByMonthValue(labels);
    return {
      labels,
      datasets: dataPoints,
    };
  }

  emptyData() {
    return {
      labels: [],
      datasets: [
        {
          data: [],
        },
      ],
    };
  }

  getTotalLeads(data) {
    let total = 0;
    for (const [ name, _data ] of Object.entries(data)) {
      total += _data.count;
    }
    return total;
  }

  formatByMonthValue(data) {
    let temp = [];
    for (const label of data) {
      let _label = moment(label, 'YYYYMMDD').format('MMM');
      temp.push(_label);
    }
    return temp;
  }

  sortByMonthValue(data) {
    return data.sort(sortFunction);

    function sortFunction(a, b) {
      if (parseInt(a) === parseInt(b)) {
        return 0;
      }
      else {
        return (parseInt(a) < parseInt(b)) ? -1 : 1;
      }
    }
  }
}();

