<template>
  <bread-crumb list-classes="breadcrumb-links breadcrumb-dark">
    <BreadCrumbItem>
      <li class="breadcrumb-item">
        <router-link to="/">
          <i class="fas fa-home"></i>
        </router-link>
      </li>
    </BreadCrumbItem>
    <BreadCrumbItem
      v-for="(route, index) in $route.matched.slice()"
      :key="route.name"
      :active="index === $route.matched.length - 1"
      style="display:inline-block"
    >
      <router-link
        :to="getBreadcrumbPath(route)"
        v-if="index < $route.matched.length - 1"
      >
        {{ route.meta.name }}
      </router-link>
      <span v-else>{{ route.meta.name }}</span>
    </BreadCrumbItem>
  </bread-crumb>
</template>

<script>
import BreadCrumb from './Breadcrumb';
import BreadCrumbItem from './BreadcrumbItem';

export default {
  name: 'route-breadcrumb',
  components: {
    BreadCrumb,
    BreadCrumbItem,
  },
  methods: {
    getBreadName(route) {
      return route.name;
    },

    getBreadcrumbPath(route) {
      if (route.meta.breadcrumb_path) {
        // Replace any dynamic segments with actual route params
        let path = route.meta.breadcrumb_path;

        Object.keys(this.$route.params).forEach(param => {
          console.log(param)
          path = path.replace(`:${param}`, this.$route.params[param]);
        });

        return path;
      }

      return { name: route.meta.name };
    }
  },
};
</script>

<style scoped></style>
