import axios from 'axios';
import { store } from '@/store';
import { _authHeader } from '../services/authHeader';

const http = new class HTTP {
  constructor() {
    this.service =  axios.create({
      baseURL: process.env.VUE_APP_VENUECREW_API_URL,
      headers: _authHeader(),
    });
    this._initInterceptors();
  }

  /**
   * Declare interseptors
   * @private
   */
  _initInterceptors() {
    // request interceptor
    this.service.interceptors.request.use(
      config => {
        config.params = config.params || {};
        config.params.source = 'venuecrew';
        return config;
      },
      error => {
        // do something with request error
        return Promise.reject(error);
      }
    );

    // response interceptor
    this.service.interceptors.response.use(
      response => {
        return response.data;
      },
      error => {
        if (error.response.status === 401) {
          store.dispatch('auth/logout');;
        }
        return Promise.reject(error);
      }
    );
  }
};

export default http.service;
