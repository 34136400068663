import { ShortlistService, VenueService } from '../services';

export const shortlist = {
  namespaced: true,
  state: {
    venues: [],
  },
  actions: {
    getShortlist({ commit, getters }, leadEventId) {
      if (getters.getShortListByLeadEventId(leadEventId).length) {
        return Promise.resolve([]);
      }
      return ShortlistService.getShortlist(leadEventId)
        .then((shortlists) => {
          shortlists = shortlists.data;
          commit('UpdateLeadShortlists', { shortlists, leadEventId });
        })
        .catch((error) => {
          dispatch('alert/error', error, { root: true });
        });
    },
    addShortlistVenue({ commit, dispatch }, data) {
      return ShortlistService.addShortlistVenue(data.leadEventId, data.venueId)
        .then((shortlists) => {
          shortlists = shortlists.data;
          commit('UpdateLeadShortlists',  { shortlists, leadEventId: data.leadEventId });
        })
        .catch((error) => {
          dispatch('alert/error', error, { root: true });
        });
    },
    removeShortlistVenue({ commit }, data) {
      return ShortlistService.removeShortlistVenue(data.leadEventId, data.venueId)
        .then((shortlists) => {
          shortlists = shortlists.data;
          commit('UpdateLeadShortlists', { shortlists, leadEventId: data.leadEventId });
        })
        .catch((error) => {
          dispatch('alert/error', error, { root: true });
        });
    },
  },
  mutations: {
    UpdateLeadShortlists(state, data) {
      state.venues = state.venues.filter(function(item) {
        return parseInt(item.leadEventId) !== parseInt(data.leadEventId);
      });
      state.venues.push(data);
    },
  },
  getters: {
    getShortListByLeadEventId: (state) => (leadEventId) => {
      let item = state.venues.find((item) => parseInt(item.leadEventId) === parseInt(leadEventId));
      return (item && item.shortlists) || [];
    },
  },
};
