export const eventTypes = {
  'wedding': 'Wedding',
  'corporate': 'Corporate Event',
  'social': 'Social Event',
};

export const weddingEventTypes = [
  'Affordable',
  'African',
  'Alternative',
  'Asian',
  'Beach',
  'Castle / Palace',
  'Garden',
  'Golf Club',
  'Hotel',
  'Jewish',
  'Luxury',
  'Manor House',
  'Modern',
  'Museum',
  'Reception Centre / Traditional',
  'Restaurant',
  'Rooftop',
  'Rustic / Country / Barn / Farm',
  'Small / Intimate',
  'Unique',
  'Warehouse / Industrial',
  'Waterview',
  'Winery',
];

export const corporateEventTypes = [
  'Awards Night',
  'Conference',
  'Corporate Party',
  'Function',
  'Gala Dinner',
  'Meeting',
  'Networking Event',
  'Seminar',
];

export const socialEventTypes = [
  '21st Birthday',
  'Bar Mitzvah',
  'Birthday Party',
  'Christmas Party',
  'Engagement Party',
  'Party',
  'Private Dining',
  'School Formal',
  'Trivia Night',
];

export const hubspotAuthUrl = process.env.VUE_APP_VENUECREW_API_URL + '/link-to-hubspot';