import { AuthService } from '../services';
import { router } from '../routes';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { status: { loggedIn: true }, user } : { status: {}, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ dispatch, commit }, { username, password, redirect }) {
      AuthService.login(username, password)
        .then((user) => {
          commit('loginSuccess', user);
          router.push(redirect ? redirect : '/dashboard');
        })
        .catch((error) => {
          commit('loginFailure', error);
          dispatch('alert/error', error, { root: true });
        });
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    loginRefresh({ dispatch, commit }) {
      return AuthService.refresh();
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status = { loggedIn: true };
      state.user = user;
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.user = null;
    },
  },
  getters: {
    getUser: (state) => {
      return state.user || {};
    },
    isAdministrator: (state) => {
      return state.user && state.user.data && state.user.data.role  === 'administrator';
    },
    isTeamlead: (state) => {
      return state.user && state.user.data && (state.user.data.vc_role  === 'Admin' || state.user.data.vc_role  === 'Team Lead');
    },
    isSales: (state) => {
      return state.user && state.user.data && state.user.data.vc_role  === 'Sales';
    },
  },
};
