import { HTTP } from '.';
import buildQuery from '@/helpers/url';
import { store } from '@/store';

export default new class UserService {
  getAll(params) {
    if (params) {
      params = buildQuery(params);
    }
    return HTTP.get('/v1/users?' + params);
  }

  getOwners() {
    return HTTP.get('/v1/owners');
  }

  getUserById(data) {
    return HTTP.get('/v1/users/' + data.id);
  }

  updateSchedulerAccess(data) {
    return HTTP.post('/v1/users/update_scheduler_access', data);
  }

  updateDisableUser(data) {
    return HTTP.post('/v1/users/toggle_user_disable', data);
  }

  updateUser(data) {
    return HTTP.put('/v1/users', data);
  }

  deleteUser(params) {
    return HTTP.delete('/v1/users?' + buildQuery(params));
  }

  createUser(data) {
    return HTTP.post('/v1/users', data);
  }
}();
