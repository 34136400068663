import { RecommendationService } from '../services';
import Vue from 'vue';

export const state = {
  recommendations: [],
};

export const actions = {
  getRecommendations({ dispatch, commit, getters }, leadEventId) {
    return RecommendationService.getRecommendations(leadEventId)
      .then((recommendations) => {
        recommendations = recommendations.data;
        commit('UpdateLeadEventRecommendations', { recommendations, leadEventId });
      })
      .catch((error) => {
        dispatch('alert/error', error, { root: true });
      });
  },
  sendRecommendation({dispatch}, data) {
    return RecommendationService.sendRecommendation(data.leadEventId, data.venues, data.template, data.subject, data.portalLink)
      .then((response) => {
        if (response?.data?.success === true) {
          dispatch('alert/success', "Recommendation email sent successfully.", { root: true });
        } else {
          dispatch('alert/error', "An error occurred while sending the email.", { root: true });
        }
      })
      .catch((error) => {
        dispatch('alert/error', error, { root: true });
      });
  },
};
export const mutations = {
  UpdateLeadEventRecommendations(state, data) {
    Vue.set(state.recommendations, data.leadEventId, data.recommendations);
  },
};
export const getters = {
  getRecommendationsByLeadEventId: (state) => (leadEventId) => {
    return state.recommendations[leadEventId] || [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};