import { HTTP } from '../services';
import moment from 'moment';

export default new class DashboardService {
  constructor() {}

  getAll() {
    return HTTP.get(
      '/v1/lead-values?from_date=' +
				moment().subtract(1, 'year').format('DD-MM-YYYY') +
				'&to_date=' +
				moment().format('DD-MM-YYYY')
    );
  }

  getAllByMonth() {
    return HTTP.get(
      '/v1/lead-values-month'
    );
  }

  formatLeadValueData(country, data) {
    if (!data) {
      return this.emptyData();
    }
    return this.formatBudgeData(country, data);
  }

  formatLeadTotalData(data) {
    if (!data) {
      return this.emptyData();
    }
    let key = 'total';
    let _data = [];
    let _data1 = [];
    let _labels = [];
    for (const [ date, value ] of Object.entries(data)) {
      let label = moment('01-' + date, 'DD-MM-YYYY').format('MMMYYYY');
      let projected = 0;
      // Calculating Projected
      if (label === moment().format('MMMYYYY')) {
        //(current leads/days passed) * (days in current month) 
        projected  = Math.round((value[key] / parseInt(moment().format('DD'))) * (moment().daysInMonth() - parseInt(moment().format('DD'))));
      }

      _labels.push(label.substring(0, 3));
      _data.push(value[key]);
      _data1.push(projected);
    }

    return {
      labels: _labels,
      datasets: [
        {
          label: "Current",
          data: _data,
        },
        {
          label: "Projected",
          data: _data1,
          backgroundColor: "#179f65",
        },
      ],
    };;
  }

  formatBudgeData(country, data) {
    let key = 'budget';
    let _data = [];
    let _labels = [];
    for (const [ date, value ] of Object.entries(data)) {
      let label = moment('01-' + date, 'DD-MM-YYYY').format('MMM') + ":CS=" + country + ":EV=" + value['excluded'];
      _labels.push(label);
      _data.push(value[key]);
    }
    return {
      labels: _labels,
      datasets: [
        {
          data: _data,
        },
      ],
    };
  }

  emptyData() {
    return {
      labels: [],
      datasets: [
        {
          data: [],
        },
      ],
    };
  }

  getTotalLeads(data) {
    let total = 0;
    for (const [ name, _data ] of Object.entries(data)) {
      total += _data.count;
    }
    return total;
  }

  sortFormatByMonthValue(data) {
    return data.sort(sortFunction);

    function sortFunction(a, b) {
      if (a.leads === b.leads) {
        return 0;
      }
      else {
        return (a.leads > b.leads) ? -1 : 1;
      }
    }
  }

  formatByMonth(data) {
    let venues = [];
    let total = this.getTotalLeads(data);
    for (const [ name, _data ] of Object.entries(data)) {
      let progress = ((_data.count / total) * 100).toFixed(1);

      venues.push({
        name: _data.name,
        leads: _data.count,
        progress,
        id: _data.id,
      });
    }
    venues = this.sortFormatByMonthValue(venues);
    return venues;
  }
}();
