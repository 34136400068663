import Vue from 'vue';

import { store } from './store';
import { router } from './routes';

import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';

import * as VueGoogleMaps from 'vue2-google-maps';

// Import services to initialize
import { LeadService } from './services';
import { InspectionService } from './services';

const Main = new class {
  constructor() {
    // plugin setup
    Vue.use(require('vue-moment'));
    Vue.use(DashboardPlugin);
    Vue.use(VueGoogleMaps, {
      load: {
        key: process.env.VUE_APP_SCHEDULER_MAPS_API_KEY,
        libraries: 'places', // This is required if you use the Autocomplete plugin
      },
      installComponents: true,
    });
    Vue.component('gmap-cluster', VueGoogleMaps.Cluster);

    // If running inside Cypress...
    if (process.env.VUE_APP_TEST === 'e2e') {
      // Ensure tests fail when Vue emits an error.
      Vue.config.errorHandler = window.Cypress.cy.onUncaughtException;
    }

    /* eslint-disable no-new */
    const app = new Vue({
      el: '#app',
      render: (h) => h(App),
      router,
      store,
    });

    // If running e2e tests...
    if (process.env.VUE_APP_TEST === 'e2e') {
      // Attach the app to the window, which can be useful
      // for manually setting state in Cypress commands
      // such as `cy.logIn()`.
      window.__app__ = app;
    }
  }

  init() {}
}();

Main.init();
