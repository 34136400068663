import EnquiryService from '@/services/enquiry.service';
import LeadEventService from "@/services/lead-event.service";
import Vue from 'vue';

export const leadEvent = {
  namespaced: true,
  state: {
    leadEvents: [],
    enquiries: [],
  },
  actions: {
    getSingle({ commit, dispatch }, id) {
      return LeadEventService.getLeadEventData(id)
        .then((leadEvent) => {
          leadEvent = leadEvent.data;
          commit('updateSingleLeadEvent', leadEvent);
        })
        .catch((error) => {
          dispatch('alert/error', error, { root: true });
        });
    },
    getEnquiries({ commit }, leadEventId) {
      return EnquiryService.getEnquiriesByLeadEventId(leadEventId)
        .then((enquiries) => {
          enquiries = enquiries.data;
          commit('updateLeadEnquiries', { leadEventId, enquiries });
        })
        .catch((error) => {
          dispatch('alert/error', error, { root: true });
        });
    },
  },
  mutations: {
    updateSingleLeadEvent(state, leadEvent) {
      state.leadEvents = state.leadEvents.filter(function(item) {
        return parseInt(item.id) !== parseInt(leadEvent.id);
      });
      state.leadEvents.push(leadEvent);
    },
    updateLeadEnquiries(state, { leadEventId, enquiries }) {
      Vue.set(state.enquiries, leadEventId, enquiries);
    },
  },
  getters: {
    getLeadEventsById: (state) => (id) => {
      let leadEvent = state.leadEvents.find((leadEvent) => parseInt(leadEvent.id) === parseInt(id));
      return leadEvent || {};
    },
    getEnquiriesByLeadEventId: (state) => (leadEventId) => {
      return state.enquiries[leadEventId] || [];
    },
  },
};
