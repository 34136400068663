export function _authHeader() {
  // return authorization header with jwt token

  let user = JSON.parse(localStorage.getItem('user'));
  let token = user && (user.token || (user.data ? user.data.token : ''));
  if (user && token ) {

    return { 'Authorization': 'Bearer ' + token };
  } else {
    return {};
  }
}
