import { HTTP } from '../services';

export default new class EnquiryService {

  EVENT_TYPE_WEDDING = "wedding";
  EVENT_TYPE_CORPORATE = "corporate";
  EVENT_TYPE_SOCIAL = 'social';

  getEnquiriesByLeadId(leadId) {
    return HTTP.get('/v1/leads/' + leadId + '/enquiries');
  }

  getEnquiriesByLeadEventId(leadEventId) {
    return HTTP.get('/v1/lead-event/' + leadEventId + '/enquiries');
  }
}();
