<template>
	<base-alert :type="className" v-if="message">
		<span class="alert-inner--icon"><i :class="icon"></i></span>
		<span class="alert-inner--text">
			<strong>{{ intro }}</strong> {{ message }}
		</span>
		<button type="button" class="close" data-dismiss="alert" @click="close" aria-label="Close">
			<span aria-hidden="true">&times;</span>
		</button>
	</base-alert>
</template>
<script>
import { mapState } from "vuex";
export default {
	created() {
		let error = this.$route.query.error;
		let success = this.$route.query.success;
		if (error) {
			this.$store.dispatch("alert/error", error);
		} else if (success) {
			this.$store.dispatch("alert/success", success);
		}
	},
	computed: mapState("alert/", {
		className: (state) => (state.type === "error" ? "danger" : "success"),
		message: (state) => state.message,
		intro: (state) => (state.type === "error" ? "Error!" : "Success!"),
		type: (state) => state.type,
		icon: (state) =>
			state.type === "error" ? "fa fa-question-circle-o " : "ni ni-like-2",
	}),
	methods: {
		close() {
			this.$store.dispatch("alert/clear");
		},
	},
	watch: {
		message() {
			if (this.message) {
				window.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth',
				});
				let self = this;
				window.setTimeout(function () {
					self.$store.dispatch("alert/clear");
				}, 6000);
			}
		},
	},
};
</script>
<style scoped>
.alert {
	border-radius: 0;
}
</style>