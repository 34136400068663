import { HTTP } from '../services';
import buildQuery from '@/helpers/url';
import moment from 'moment';
import momenttz from 'moment-timezone';

export default new class InspectionService {
  INSPECTION_STATUS_REQUESTED = "requested";
  INSPECTION_STATUS_RESCHEDULED = "rescheduled";
  INSPECTION_STATUS_CONFIRMED = "confirmed";
  INSPECTION_STATUS_CANCELLED = "cancelled";
  INSPECTION_STATUS_ATTENDED = "attended";
  INSPECTION_STATUS_NOT_ATTENDED = "not_attended";

  constructor() {}

  async paginate(page, search, sortBy, sortOrder, country) {
    let params = {
      page: page || 1,
      search: search || '',
      sort_by: this.getSortOrderField(sortBy) || '',
      sort_order: this.getSortOrderType(sortOrder) || '',
      country,
    };

    let data = await HTTP.get('/v1/inspections?' + buildQuery(params));
    return data;
  }

  getInspectionByLeadEventId(leadEventId) {
    return HTTP.get('/v1/lead-event/' + leadEventId + '/inspections');
  }

  getSalesWithoutInspections(leadEventId) {
    return HTTP.get('/v1/lead-event/' + leadEventId + '/sales-without-inspection');
  }

  getInspectionByKey(uniqueId) {
    return HTTP.get('/v1/inspections/' + uniqueId);
  }

  formatInspectionFormData(data) {
    if (typeof data.venues === 'object') {
      let venues = [];
      let times = {};
      for (const [key, value] of Object.entries(data.venues)) {
        if (value === true) {
          venues.push(key);
          let time = data.times[key];
          let inspection_timezone = data.timezones[key];
          var inspectionDateObj = momenttz.tz(moment(time, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DDTHH:mm:ss'), inspection_timezone);
          times[key.toString()] = moment.utc(inspectionDateObj.utc()).format();
        }
      }
      data.venues = venues;
      data.times = times;
    }
    return data;
  }

  addInspectionRequest(leadEventId, data) {
    data = this.formatInspectionFormData(data);
    return HTTP.post('/v1/lead-event/' + leadEventId + '/inspections', data);
  }

  editInspectionRequest(leadEventId, data) {
    data = this.formatInspectionFormData(data);
    return HTTP.put('/v1/lead-event/' + leadEventId + '/inspections', data);
  }

  confirmInspectionBooking(inspectionUniqueId, data) {
    return HTTP.put('/v1/inspections/' + inspectionUniqueId + '/confirm', data);
  }

  cancelInspection(inspectionId) {
    return HTTP.put('/v1/inspections/' + inspectionId + '/cancel');
  }

  getSortOrderField(sort) {
    let translation = {
      id: 'id',
      name: 'first_name',
      estimatedBudget: 'estimated_budget',
      inspectionDate: 'inspection_date',
      inspections: 'inspections',
      estimatedGuests: 'estimated_guests',
      eventType: 'event_type',
      createdDate: 'event_date',
    };
    return translation[sort] || '';
  }

  getSortOrderType(type) {
    let translation = {
      ascending: 'asc',
      descending: 'desc',
    };
    return translation[type] || '';
  }

  changeInspectionStatus(inspection_id, params) {
    return HTTP.put('/v1/inspections/' + inspection_id + '/change-status', params);
  }
}();