import { HTTP } from '../services';
import {store as Store} from "../store";

export default new class ShortlistService {
  constructor() {
  }
  getShortlist(leadEventId) {
    return HTTP.get('/v1/lead-event/' + leadEventId + '/shortlists');
  }

  addShortlistVenue(leadEventId, venueId) {
    return HTTP.post('/v1/lead-event/' + leadEventId + '/shortlist/' + venueId);
  }

  removeShortlistVenue(leadEventId, venueId) {
    return HTTP.delete('/v1/lead-event/' + leadEventId + '/shortlist/' + venueId);
  }
};
