import { HTTP } from '.';
import buildQuery from '@/helpers/url';

export default new class EnquiryAuditService {
  constructor() {}

  async paginate(page, search, date, timezone, failedOnly) {
    let params = {
      page: page || 1,
      search: search || '',
      date,
      timezone,
      show_failed_only: failedOnly ? 1 : 0,
    };

    let data = await HTTP.get('/v1/reports/enquiry-daily-audits?' + buildQuery(params));
    return data;
  }

  async resendEnquiryData(data) {
    return await HTTP.post('/v1/leads', data);
  }
}();