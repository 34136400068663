var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'ni ni-shop text-primary',
          path: '/dashboard',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Leads',
          icon: 'ni ni-circle-08 text-primary',
          path: '#',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Leads',
            icon: 'ni ni-circle-08 text-primary',
            path: '/leads',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Lead Distribution',
            path: '/leads/lead-distribution',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Distribution Rules',
            path: '/leads/distribution-rules',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Lead Duplicates',
            path: '/leads/lead-duplicates',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Venues',
          icon: 'ni ni-building text-primary',
          path: '/venues',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Inspections',
          icon: 'ni ni-calendar-grid-58 text-primary',
          path: '/inspections/inspection-requests'
        }}}),(_vm.isTeamLead || _vm.isSales)?_c('sidebar-item',{attrs:{"link":{
          name: 'Reports',
          icon: 'ni ni-chart-pie-35 text-primary',
          path: '#',
        }}},[(_vm.isTeamLead)?_c('sidebar-item',{attrs:{"link":{
            name: 'Inspections',
            path: '/reports/inspections',
          }}}):_vm._e(),(_vm.isTeamLead)?_c('sidebar-item',{key:"au_performance",attrs:{"link":{
            name: 'Leading Indicator - AU',
            path: '/reports/au/performance',
          }}}):_vm._e(),(_vm.isTeamLead)?_c('sidebar-item',{key:"uk_performance",attrs:{"link":{
            name: 'Leading Indicator - UK',
            path: '/reports/uk/performance',
          }}}):_vm._e(),(_vm.isSales)?_c('sidebar-item',{key:"uk_performance",attrs:{"link":{
            name: 'Leading Indicator',
            path: '/reports/' + _vm.userCountry + '/performance',
          }}}):_vm._e(),(_vm.isTeamLead)?_c('sidebar-item',{key:"au_venue_audit_log",attrs:{"link":{
            name: 'Venue Audit Log - AU',
            path: '/reports/au/venue-audit-log',
          }}}):_vm._e(),(_vm.isTeamLead)?_c('sidebar-item',{key:"uk_venue_audit_log",attrs:{"link":{
            name: 'Venue Audit Log - UK',
            path: '/reports/uk/venue-audit-log',
          }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
            name: 'Venue Crew Performance',
            path: '/reports/venuecrew-performance',
          }}}),(_vm.isTeamLead)?_c('sidebar-item',{key:"venues_sales_hit_list",attrs:{"link":{
            name: 'Venue Sales Hit List',
            path: '/reports/venues-sales-hit-list',
          }}}):_vm._e(),(_vm.isTeamLead)?_c('sidebar-item',{attrs:{"link":{
            name: 'Venue Specialist',
            path: '/reports/venue-specialist',
          }}}):_vm._e(),(_vm.isTeamLead)?_c('sidebar-item',{attrs:{"link":{
            name: 'Enquiry Audits',
            path: '/reports/enquiry-audits',
          }}}):_vm._e()],1):_vm._e()],1),(_vm.development=='http://localhost')?_c('template',{slot:"links-after"},[_c('hr',{staticClass:"my-3"}),_c('h6',{staticClass:"navbar-heading p-0 text-muted"},[_vm._v("Developer Documentation")]),_c('ul',{staticClass:"navbar-nav mb-md-3"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation","target":"_blank"}},[_c('i',{staticClass:"ni ni-spaceship"}),_c('span',{staticClass:"nav-link-text"},[_vm._v("Getting started")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/foundation/colors.html","target":"_blank"}},[_c('i',{staticClass:"ni ni-palette"}),_c('span',{staticClass:"nav-link-text"},[_vm._v("Foundation")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/components/avatars.html","target":"_blank"}},[_c('i',{staticClass:"ni ni-ui-04"}),_c('span',{staticClass:"nav-link-text"},[_vm._v("Components")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/components/plugin_datepicker.html","target":"_blank"}},[_c('i',{staticClass:"ni ni-chart-pie-35"}),_c('span',{staticClass:"nav-link-text"},[_vm._v("Plugins")])])])])]):_vm._e()],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('global-alert'),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }