import Vue from 'vue';
import { ReportsService } from '../services';

export default {
  namespaced: true,
  state: {
    inspections: {},
    venue_specialists: {},
    sales_hit_list: [],
    leading_indicator_uk: {},
    leading_indicator_au: {},
    excluded_leading_indicator_vcs: [],
  },
  actions: {
    getInspections({ commit, dispatch }) {
      return ReportsService.getInspectionsReport()
        .then(data => {
          data = data.data;
          commit('SET_INSPECTIONS_REPORT', data);
        })
        .catch(error => {
          dispatch('alert/error', error, { root: true });
        });
    },
    getVenueSpecialists({ commit, dispatch }) {
      return ReportsService.getVenueSpecialistsReport()
        .then(data => {
          data = data.data;
          commit('SET_VENUE_SPECIALISTS_REPORT', data);
        })
        .catch(error => {
          dispatch('alert/error', error, { root: true });
        });
    },
    getSalesHitList({ commit, dispatch }, country) {
      return ReportsService.getSalesHitList(country)
        .then(data => {
          data = data.data;
          commit('SET_SALES_HIT_LIST_REPORT', data);
        })
        .catch(error => {
          dispatch('alert/error', error, { root: true });
        });
    },
    getLeadingIndicators({ commit, dispatch, getters }, params) {
      return ReportsService.getLeadingIndicatorReport(params)
        .then(data => {
          data = data.data;
          commit('SET_LEADING_INDICATOR_REPORT', {
            reports: data,
            month: params.month,
            country: params.country,
          });
        })
        .catch(error => {
          dispatch('alert/error', error, { root: true });
        });
    },
    getExcludedLeadingIndicatorVCs({ commit, dispatch, getters }) {
      return ReportsService.getExcludedLeadingIndicatorVCs()
        .then(data => {
          data = data.data;
          commit('SET_EXCLUDED_LEADING_INDICATOR_REPORT_VC', data);
        })
        .catch(error => {
          dispatch('alert/error', error, { root: true });
        });
    },
  },
  mutations: {
    SET_INSPECTIONS_REPORT(state, data) {
      state.inspections = data;
    },
    SET_VENUE_SPECIALISTS_REPORT(state, data) {
      state.venue_specialists = data;
    },
    SET_SALES_HIT_LIST_REPORT(state, data) {
      state.sales_hit_list = data;
    },
    SET_LEADING_INDICATOR_REPORT(state, data) {
      if (data.country.toLowerCase() === 'au') {
        Vue.set(state.leading_indicator_au, data.month, data.reports);
      } else {
        Vue.set(state.leading_indicator_uk, data.month, data.reports);
      }
    },
    SET_EXCLUDED_LEADING_INDICATOR_REPORT_VC(state, data) {
      state.excluded_leading_indicator_vcs = data;
    },
  },
  getters: {
    getLeadingIndicatorReports: (state) => (country, month) => {
      let stateitem = country === 'au' ? state.leading_indicator_au : state.leading_indicator_uk;
      let item = stateitem[month];
      return item || [];
    },
    getLeadingSingleIndicatorReports: (state) => (country, month, venuespecialist) => {
      let stateitem = country === 'au' ? state.leading_indicator_au : state.leading_indicator_uk;
      let selectedData = stateitem[month];
      if (!selectedData) {
        return [];
      }
      let data = selectedData.find((item) => parseInt(item.venue_specialist.id) === parseInt(venuespecialist));
      return data || [];
    },
    getExcludedLeadingIndicatorVCs: (state) => () => {
      return state.excluded_leading_indicator_vcs.map(x => x.id);
    },
  },
};


