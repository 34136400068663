<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-secondary py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body mb-7">
          <div class="row justify-content-center">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'not-found',
};
</script>
