<template>
	<base-nav
		container-classes="container-fluid"
		class="navbar-top border-bottom navbar-expand"
		:class="{ 'bg-gradient-info navbar-dark': type === 'default' }"
	>

		<!-- Search form -->
		<form
			class="navbar-search form-inline mr-sm-3"
			:class="{
				'navbar-search-light': type === 'default',
				'navbar-search-dark': type === 'light'
			}"
			id="navbar-search-main"
		>
			<!-- <div class="form-group mb-0">
        <div class="input-group input-group-alternative input-group-merge">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </div>
          <input class="form-control" placeholder="Search" type="text" />
        </div>
      </div> -->
			<button
				type="button"
				class="close"
				data-action="search-close"
				data-target="#navbar-search-main"
				aria-label="Close"
			>
				<span aria-hidden="true">×</span>
			</button>
		</form>
		<!-- Navbar links -->
		<ul class="navbar-nav align-items-center ml-md-auto">
			<li class="nav-item d-xl-none">
				<!-- Sidenav toggler -->
				<div
					class="pr-3 sidenav-toggler"
					:class="{
						active: $sidebar.showSidebar,
						'sidenav-toggler-dark': type === 'default',
						'sidenav-toggler-light': type === 'light',
					}"
					@click="toggleSidebar"
				>
					<div class="sidenav-toggler-inner">
						<i class="sidenav-toggler-line"></i>
						<i class="sidenav-toggler-line"></i>
						<i class="sidenav-toggler-line"></i>
					</div>
				</div>
			</li>
			<li class="nav-item d-sm-none">
				<a
					class="nav-link"
					href="#"
					data-action="search-show"
					data-target="#navbar-search-main"
				>
					<i class="ni ni-zoom-split-in"></i>
				</a>
			</li>
		</ul>

		<ul class="navbar-nav align-items-center ml-auto ml-md-0">
			<base-dropdown>
				<base-button slot="title-container" size="sm" type="secondary" class="dropdown-toggle">
					<img :src="currentCountry" />
				</base-button>
				<div class="dropdown-header noti-title">
					<h6 class="text-overflow m-0">Change Country</h6>
				</div>
				<li>
					<a class="dropdown-item" href="#" @click="swapCountry('AU')">
						<img src="/img/icons/flags/AU.png" /> AU
					</a>
				</li>
				<li>
					<a class="dropdown-item" href="#" @click="swapCountry('UK')">
						<img src="/img/icons/flags/UK.png" /> UK
					</a>
				</li>
			</base-dropdown>
		</ul>

		<ul class="navbar-nav align-items-center ml-auto ml-md-0">
			<base-dropdown
				id="nav_link_user_main" 
				menu-on-right
				class="nav-item"
				tag="li"
				title-tag="a"
				title-classes="nav-link pr-0"
			>
				<a
					href="#" class="nav-link pr-0" @click.prevent
					slot="title-container">
					<div class="media align-items-center">
						<span class="avatar avatar-sm rounded-circle">
							<img alt="User Avatar" @error="setFallbackImageUrl" v-if="currentUser" v-bind:src="!!currentUser.thumbnail ? currentUser.thumbnail : avatar" />
						</span>
						<div class="media-body ml-2 d-none d-lg-block">
							<span v-if="currentUser" class="mb-0 text-sm  font-weight-bold">{{ currentUser.displayName }}</span>
						</div>
					</div>
				</a>

				<template>
					<div class="dropdown-header noti-title">
						<h6 class="text-overflow m-0">Welcome!</h6>
					</div>
					<a @click="linkToHubSpot" class="dropdown-item" v-if="isAdmin">
						<i class="fa fa-link"></i>
						<span>Link To HubSpot</span>
					</a>
					<a @click="showUsers" class="dropdown-item" v-if="isTeamLead">
						<i class="fa fa-user"></i>
						<span>Users</span>
					</a>
					<a @click="showVenueReportSettings" class="dropdown-item" v-if="isAdmin">
						<i class="fa fa-cog"></i>
						<span>Venue Report Values</span>
					</a>
					<a @click="showKarenSettings" class="dropdown-item" v-if="isAdmin">
						<i class="fa fa-cog"></i>
						<span>Karen Settings</span>
					</a>
					<a @click="logout" class="dropdown-item" id="logout_button">
						<i class="ni ni-user-run"></i>
						<span>Logout</span>
					</a>
				</template>
			</base-dropdown>
		</ul>
	</base-nav>
</template>
<script>

import { BaseNav } from "@/components";
import avatar from "@/assets/img/icons/common/avatar.svg";
import {hubspotAuthUrl} from '@/../app.config';

export default {
	components: {
		BaseNav,
	},
	props: {
		type: {
			type: String,
			default: "default", // default|light
		},
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
		isAdmin() {
			return this.$store.getters["auth/isAdministrator"];
		},
		isTeamLead() {
			return this.$store.getters["auth/isTeamlead"];
		},
		routeName() {
			const { name } = this.$route;
			return this.capitalizeFirstLetter(name);
		},
		currentCountry() {
			let country = !!this.$store.state.country.country ? this.$store.state.country.country : "AU";
			if (!(country.toUpperCase() === 'AU' || country.toUpperCase() === 'UK')) {
				country = 'AU';
			}
			return "/img/icons/flags/" + country + ".png";
		},
	},
	created() {
		let country = localStorage.getItem('country') ? localStorage.getItem('country') : this.$store.state.auth.user.country;
		this.$store.dispatch('country/setCountry', country);
	},
	data() {
		return {
			showMenu: false,
			searchModalVisible: false,
			searchQuery: "",
			avatar,
		};
	},
	methods: {
		toggleSidebar() {
			this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
		},
		hideSidebar() {
			this.$sidebar.displaySidebar(false);
		},
		setFallbackImageUrl(event) {
			event.target.src = '/img/icons/common/avatar.svg';
		},
		swapCountry: function(country) {
			this.$store.commit('country/setCountry', country);
			window.setTimeout(function() {
				window.location.reload();
			}, 500);
		},
		logout() {
			this.$store.dispatch('auth/logout');
			this.$router.push({path: '/login'});
		},
		linkToHubSpot() {
			window.location = hubspotAuthUrl;
		},
		showUsers() {
			this.$router.push({path: '/users/all'});
		},
		showVenueReportSettings() {
			this.$router.push({path: '/reports/venue-report-values'});
		},
		showKarenSettings() {
			this.$router.push({path: '/settings/karen'});
		},
	},
};
</script>
