import { authHeader } from '../routes';
import { router } from '../routes';
import { _authHeader } from './authHeader';
import { HTTP } from '.';
import { store as Store } from '../store';

const AUTH_URL = process.env.VUE_APP_VENUECREW_AUTH_URL + '/jwt-auth/v1/token';
const AUTH_REFRESH_URL = process.env.VUE_APP_VENUECREW_AUTH_URL + '/scheduler/v1/token/refresh';

export default new class AuthService {
  constructor() {}

  login(username, password) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password }),
    };

    // Better to use fetch, because axios should always point to api server.
    return sendLoginRequest(AUTH_URL, requestOptions, this.handleResponse);
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
  }

  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          this.logout();
          location.reload(true);
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      if (data.statusCode !== 200) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      } else {
        return data;
      }
    });
  }

  refresh() {
    let user = JSON.parse(localStorage.getItem('user'));
    let token = user && (user.token || (user.data ? user.data.token : ''));
    if (!user && !token) {
      this.logout();
      return false;
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };
    return fetch(AUTH_REFRESH_URL, requestOptions).then(this.handleResponse).then((user) => {
      return user.id ? true : false;
    });
  }
}();

function sendLoginRequest($url, requestOptions, callback) {
  return fetch($url, requestOptions).then(callback).then((user) => {
    let token = user && (user.token || (user.data ? user.data.token : ''));
    // login successful if there's a jwt token in the response
    if (token) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(user));
      HTTP.defaults.headers = _authHeader();
      return user;
    }

    return false;
  });
}
