import Vue from 'vue';
import Vuex from 'vuex';

import alert from './alert.module'; // todo => export default discuss
import { auth } from './auth.module';
import { country } from './country.module';
import { dashboard } from './dashboard.module';
import { leads } from './leads.module';
import { leadEvent } from './lead-event.module';
import venues from './venues.module';
import users from './users.module';
import recommendations from './recommendations.module';
import inspections from './inspections.module';
import { shortlist } from './shortlist.module';
import reports from './reports.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    auth,
    country,
    dashboard,
    leads,
    leadEvent,
    inspections,
    shortlist,
    venues,
    users,
    recommendations,
    alert,
    reports,
  },
});
