import { DashboardService } from '../services';

export const dashboard = {
  namespaced: true,
  state: {
    data: {},
    dataByMonth: {},
  },
  actions: {
    getAll({ commit, dispatch }) {
      return DashboardService.getAll()
        .then(data => {
          data = data.data;
          commit('setDashboardData', data);
        })
        .catch(error => {
          dispatch('alert/error', error, { root: true });
        });
    },
    getAllMonth({ commit, dispatch }) {
      return DashboardService.getAllByMonth()
        .then(data => {
          data = data.data;
          commit('setDashboardDataByMonth', data);
        })
        .catch(error => {
          dispatch('alert/error', error, { root: true });
        });
    },
  },
  mutations: {
    setDashboardData(state, data) {
      state.data = data;
    },
    setDashboardDataByMonth(state, data) {
      state.dataByMonth = data;
    },
  },
};


