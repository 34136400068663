import buildQuery from '@/helpers/url';
import { HTTP } from '.';
import { store as Store } from '../store';

export default new class RecommendationService {
  constructor() {}

  getRecommendations(leadEventId) {
    return HTTP.get('/v1/lead-event/' + leadEventId + '/recommendations');
  }
  
  sendRecommendation(leadEventId, venues, template, subject, portalLink) {
    return HTTP.post('/v1/lead-event/' + leadEventId + '/recommendations/send', {
      venues,
      template,
      subject,
      portal_link: portalLink,
    });
  }

  getRecommendationPortalLink(leadId, isBeingImpersonated = false) {
    return HTTP.get('/v1/leads/' + leadId + '/recommendations/portal-link?' + buildQuery({'is_being_impersonated': isBeingImpersonated}));
  }
}();
