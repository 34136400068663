<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-shop text-primary',
            path: '/dashboard',
          }">
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Leads',
            icon: 'ni ni-circle-08 text-primary',
            path: '#',
          }">
          <sidebar-item
            :link="{
              name: 'Leads',
              icon: 'ni ni-circle-08 text-primary',
              path: '/leads',
            }">
          </sidebar-item>
          <sidebar-item
            :link="{
              name: 'Lead Distribution',
              path: '/leads/lead-distribution',
            }">
          </sidebar-item>
          <sidebar-item
            :link="{
              name: 'Distribution Rules',
              path: '/leads/distribution-rules',
            }">
          </sidebar-item>
          <sidebar-item
            :link="{
              name: 'Lead Duplicates',
              path: '/leads/lead-duplicates',
            }">
          </sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Venues',
            icon: 'ni ni-building text-primary',
            path: '/venues',
          }">
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Inspections',
            icon: 'ni ni-calendar-grid-58 text-primary',
            path: '/inspections/inspection-requests'
          }">
        </sidebar-item>
        <sidebar-item
          v-if="isTeamLead || isSales"
          :link="{
            name: 'Reports',
            icon: 'ni ni-chart-pie-35 text-primary',
            path: '#',
          }">
          <sidebar-item
            v-if="isTeamLead"
            :link="{
              name: 'Inspections',
              path: '/reports/inspections',
            }">
          </sidebar-item>

          
          <sidebar-item
            v-if="isTeamLead"
            key="au_performance"
            :link="{
              name: 'Leading Indicator - AU',
              path: '/reports/au/performance',
            }">
          </sidebar-item>
          <sidebar-item
            v-if="isTeamLead"
            key="uk_performance"
            :link="{
              name: 'Leading Indicator - UK',
              path: '/reports/uk/performance',
            }">
          </sidebar-item>
          <sidebar-item
            v-if="isSales"
            key="uk_performance"
            :link="{
              name: 'Leading Indicator',
              path: '/reports/' + userCountry + '/performance',
            }">
          </sidebar-item>
          <sidebar-item
            v-if="isTeamLead"
            key="au_venue_audit_log"
            :link="{
              name: 'Venue Audit Log - AU',
              path: '/reports/au/venue-audit-log',
            }">
          </sidebar-item>
          <sidebar-item
            v-if="isTeamLead"
            key="uk_venue_audit_log"
            :link="{
              name: 'Venue Audit Log - UK',
              path: '/reports/uk/venue-audit-log',
            }">
          </sidebar-item>
          <sidebar-item
            :link="{
              name: 'Venue Crew Performance',
              path: '/reports/venuecrew-performance',
            }">
          </sidebar-item>
          <sidebar-item
            v-if="isTeamLead"
            key="venues_sales_hit_list"
            :link="{
              name: 'Venue Sales Hit List',
              path: '/reports/venues-sales-hit-list',
            }">
          </sidebar-item>
          <sidebar-item
            v-if="isTeamLead"
            :link="{
              name: 'Venue Specialist',
              path: '/reports/venue-specialist',
            }">
          </sidebar-item>
          <sidebar-item
            v-if="isTeamLead"
            :link="{
              name: 'Enquiry Audits',
              path: '/reports/enquiry-audits',
            }">
          </sidebar-item>
        </sidebar-item>
      </template>

      <template slot="links-after" v-if="development=='http://localhost'">
        <hr class="my-3">
        <h6 class="navbar-heading p-0 text-muted">Developer Documentation</h6>

        <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <a class="nav-link" href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation" target="_blank">
              <i class="ni ni-spaceship"></i>
              <span class="nav-link-text">Getting started</span>
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/foundation/colors.html" target="_blank">
              <i class="ni ni-palette"></i>
              <span class="nav-link-text">Foundation</span>
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/components/avatars.html" target="_blank">
              <i class="ni ni-ui-04"></i>
              <span class="nav-link-text">Components</span>
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/components/plugin_datepicker.html" target="_blank">
              <i class="ni ni-chart-pie-35"></i>
              <span class="nav-link-text">Plugins</span>
            </a>
          </li>
        </ul>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
      <global-alert/>
      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import { FadeTransition } from 'vue2-transitions';
import GlobalAlert from '@/views/Widgets/GlobalAlert.vue';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}


export default {
  data () {
    return {
      development: process.env.VUE_APP_BASE_URL,
    };
  },
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
    GlobalAlert,
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        initScrollbar('sidenav');
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/isAdministrator"];
    },
    isTeamLead() {
      return this.$store.getters["auth/isTeamlead"];
    },
    isSales() {
      return this.$store.getters["auth/isSales"];
    },
    userCountry() {
      let user = this.$store.getters["auth/getUser"];
      let country = user.data.country || '';
      return country.toLowerCase();
    },
  },
};
</script>
<style lang="scss">
</style>