import { UserService } from '../services';
import Vue from 'vue';

export const state = {
  items: [],
  owners: [],
};

export const actions = {
  getAll({ commit, dispatch }, params) {
    return UserService.getAll(params)
      .then((users) => {
        users = users.data;
        commit('setUsers', users);
      })
      .catch((error) => {
        dispatch('alert/error', error, { root: true });
      });
  },
  getOwners({ commit, dispatch }, params) {
    return UserService.getOwners(params)
      .then((owners) => {
        owners = owners.data;
        commit('setOwners', owners);
      })
      .catch((error) => {
        dispatch('alert/error', error, { root: true });
      });
  },
  async updateUser({ commit, dispatch }, params) {
    return UserService.updateUser(params)
      .then(async (data) => {
        await dispatch('getAll');
        return data;
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 422) {
          dispatch('alert/error', error.response.data.error.message, { root: true });
        } else {
          dispatch('alert/error', error, { root: true });
        }
      });
  },
  async deleteUser({ commit, dispatch }, params) {
    return UserService.deleteUser(params)
      .then(async (data) => {
        return data;
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 422) {
          dispatch('alert/error', error.response.data.error.message, { root: true });
        } else {
          dispatch('alert/error', error, { root: true });
        }
      });
  },
  async createUser({ commit, dispatch }, params) {
    return UserService.createUser(params)
      .then(async (data) => {
        await dispatch('getAll');
        return data;
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 422) {
          dispatch('alert/error', error.response.data.error.message, { root: true });
        } else {
          dispatch('alert/error', error, { root: true });
        }
      });
  },
  updateSchedulerAccess({ commit, dispatch }, params) {
    return UserService.updateSchedulerAccess(params)
      .then(() => {
      })
      .catch((error) => {
        dispatch('alert/error', error, { root: true });
      });
  },
  updateDisableUser({ commit, dispatch }, params) {
    return UserService.updateDisableUser(params)
      .then(() => {
      })
      .catch((error) => {
        dispatch('alert/error', error, { root: true });
      });
  },
};
export const mutations = {
  setUsers(state, users) {
    state.items = users;
  },
  setOwners(state, owners) {
    state.owners = owners;
  },
};
export const getters = {
  getUserById: (state) => (id) => {
    let user = state.items.find((user) => parseInt(user.user_id) === parseInt(id));
    return user || {};
  },
  getUserByEmail: (state) => (email) => {
    let owner = state.owners.find((owner) => owner.email === email);
    return owner || {};
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
