export const country = {
  namespaced: true,
  state: {
    country:  localStorage.getItem('country') !== 'AU' && localStorage.getItem('country') !== 'UK' ? "AU" : localStorage.getItem('country'),
    currency: localStorage.getItem('country') && localStorage.getItem('country').toLowerCase() === 'uk' ? '£' : '$',
    measurement: localStorage.getItem('country') && localStorage.getItem('country').toLowerCase() === 'uk' ? 'mi' : 'km',
  },
  actions: {
    setCountry({ commit }, country) {
      commit('setCountry', country);
    },
  },
  mutations: {
    setCountry(state, country) {
      if (!country || (country !== 'AU' && country !== 'UK')) {
        return false;
      }
      state.country = country;
      if (country && country.toLowerCase() === 'uk') {
        state.currency = '£';
        state.measurement = 'mi';
      } else {
        state.currency = '$';
        state.measurement = 'km';
      }
      
      localStorage.setItem('country', country);
    },
  },
  getters: {
    country: state => state.country,
    currency: state => state.currency,
    measurement: state => state.measurement,
  },
};
